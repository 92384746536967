import React, {useState, useEffect} from 'react';
import {Button} from "../ButtonElement";
import Icon from "./Icon";
import {StyledInput} from "./Input";
import Logo from '../../images/logo.png';
import { FaGoogle} from "react-icons/fa";
import { WelcomeText, InputContainer, ButtonContainer, LoginWith, IconsContainer, LogoHeader,
    ForgotPassword, MainContainer, BackgroundDiv, ArrowForward, ArrowRight, BorderContainer} from "./SignInComponents";
import background from "../../images/loginGradient.jpg"
import { UserAuth } from '../../context/AuthContext';
import {Link as LinkR} from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import Alert from "react-bootstrap/Alert";
import { useRef } from 'react';
import { firebaseAuth } from '../../firebaseServices';

function SignIn() {

    const [hover, setHover] = useState(false);
    const emailRef = useRef();
    const passwordRef = useRef();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }

    const {googleSignIn, user, signIn, resetPwd} = UserAuth();
    
    const handleGoogleSignIn = async () => {
      try {
        await googleSignIn();
      } catch (error) {
        console.log(error);
      }
    }

  async function handleSubmit(e) {
      e.preventDefault();
      try {
        setError('')
        setLoading(true)
        await signIn(firebaseAuth, emailRef.current.value, passwordRef.current.value);
      } catch (e) {
        setError('Invalid Credentials.')
        console.log(e.message)
      }
      setLoading(false)      
    }
  
    async function resetHandler(e) {
      e.preventDefault();
      try {
        setError('')
        setLoading(true)
        await resetPwd(firebaseAuth, emailRef.current.value);
        setError('Password reset email sent.')
      } catch (e) {
        setError('Enter a valid account email to reset.')
        console.log(e.message)
      }
      setLoading(false)      
    }
  

  const navigate = useNavigate();

    useEffect(() => {
    if(user != null && user.uid != null) {
      navigate('/user')
    }
  }, [user, navigate]);

  return (
    <BackgroundDiv style={{ backgroundImage: `url(${background})` }}>
    <MainContainer>
      <BorderContainer>
      <LinkR to="/" style={{textDecoration: "none"}}>
      <LogoHeader>
        <img src={Logo} alt="Logo" style={{ width: '1.75rem', paddingRight: '5px',}}></img>
        <p style={{marginRight: '5px'}}> UQ Fintech</p>
      </LogoHeader>
      </LinkR>
      <WelcomeText>Welcome</WelcomeText>
      <form style={
        {
          width: "80%", paddingBottom: "1.5rem", 
          borderBottom: "1px solid white", 
          marginBottom: "0.5rem"}
        } onSubmit={handleSubmit}>
        <InputContainer>
          <input id="email" type="text" placeholder="Email" style={StyledInput} required ref={emailRef}/>
          <input id="password" type="password" placeholder="Password" style={StyledInput} required ref={passwordRef}/>
          {error && <Alert variant="danger" style={{textAlign: "center"}}>{error}</Alert>}
        </InputContainer>
        <ButtonContainer>
          <Button onMouseEnter={onHover} onMouseLeave={onHover} type="submit" disabled={loading}>
              Sign In {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
          <IconsContainer>
          <Icon color={"black"}>
            <FaGoogle onClick={handleGoogleSignIn} />
          </Icon>
          </IconsContainer>
        </ButtonContainer>
      </form>
      <LoginWith>Or Create an Account</LoginWith>
      <LinkR to="/signup" style={{textDecoration: "none"}}>
        <ButtonContainer style={{justifyContent: "center"}}>
          <Button to='/signup' onMouseEnter={onHover} onMouseLeave={onHover}>
            Create Account
          </Button>
        </ButtonContainer>
      </LinkR>
      </BorderContainer>
        <ForgotPassword onClick={resetHandler} disabled={loading}>Forgot Password ?</ForgotPassword>
      </MainContainer>
    </BackgroundDiv>
  );
}

export default SignIn;