import {React, useState, useEffect} from 'react';
import { MainContainer, BackgroundDiv, BorderContainer,} from "./SignUpComponents";
import background from "../../images/loginGradient.jpg"
function Spinner() {

  return (
    <BackgroundDiv style={{ backgroundImage: `url(${background})` }}>
    <MainContainer>
      <BorderContainer>
      <h1>Spinner Content</h1>
      </BorderContainer>
        <p>Other Content</p>
      </MainContainer>
    </BackgroundDiv>
  );
}

export default Spinner;