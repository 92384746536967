export const StyledInput = {
  background: "black",
  borderRadius: "2rem",
  width: "80%",
  height: "3rem",
  padding: "1rem",
  margin: "0.5rem 0rem 0.5rem",
  border: "solid 2px",
  borderColor: "white",
  outline: "none",
  color: "#FFFFFF",
  fontSize: "1rem",
  fontWeight: "bold",
  /**&:focus {
    display: inline-block;
    box-shadow: 0 7px 32px 0 rgba(141, 91, 238, 0.6);
    backdrop-filter: blur(12rem);
    border-radius: 2rem;
  }
  &::placeholder {
    color: #b9abe099;
    font-weight: 100;
    font-size: 1rem;
  }**/
};

