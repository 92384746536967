// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDZyiK4nLmJdCB53rU3evkiOcrteoTRxlY",
  authDomain: "uqfintech-b892c.firebaseapp.com",
  projectId: "uqfintech-b892c",
  storageBucket: "uqfintech-b892c.appspot.com",
  messagingSenderId: "754461949979",
  appId: "1:754461949979:web:4affd89a5045d9b37238be",
  measurementId: "G-0L8BR6VLXG"
};



// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
export const database = getFirestore(firebaseApp);