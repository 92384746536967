export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'The Society',
    headline: 'About Us',
    description: 'The University of Queensland Fintech Society connects STEM students with the financial technology industry through a variety of academic, professional, and social activities. The society aims to educate and create engaging student experiences to support these objectives.',
    buttonLabel: 'Meet the Team',
    imgStart: false,
    img: require('../../images/info1.png'),
    alt: 'Info Image 1',
    dark: false,
    primary: false,
    darkText: true,
    lightBorder: true,
    button_link: '/team'
};

export const homeObjTwo = {
    id: 'events',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Events',
    headline: 'What We Do',
    description: 'The University of Queensland Fintech Society connects students with the financial technology industry through education and networking events. Events such as our annual Problem Solving & Industry Night, trading workshops, and poker tournaments, provide networking opportunities and a supportive community for members.',
    buttonLabel: 'Event Calendar',
    imgStart: true,
    img: require('../../images/info2.jpg'),
    alt: 'Info Image 2',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: '/events'
};

export const homeObjThree = {
    id: 'sponsors',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Our Sponsors',
    headline: 'Fintech Careers',
    description: "UQ Fintech's partnerships with major Australian and International Fintech companies offers students access to cutting-edge industry insights and the chance to network with key players in the space. By providing a direct link to the Fintech industry, the society prepares students for a promising future in the fast-growing field of financial technology.",
    buttonLabel: 'Our Sponsors',
    imgStart: false,
    img: require('../../images/info3.jpg'),
    alt: 'Info Image 3',
    dark: false,
    primary: false,
    darkText: true,
    lightBorder: true,
    button_link: '/sponsors'
};

export const teamObjAbout = {
    id: 'about us',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'About Us',
    headline: "Meet The Team",
    description: "At the University of Queensland Fintech Society, our team embodies a remarkable diversity of skills and backgrounds. We take pride in being interdisciplinary problem solvers, pooling our collective knowledge from STEM fields and beyond to tackle the multifaceted challenges of the fintech industry. This unique blend of expertise enables us to approach problems from various angles, fostering innovative solutions and fostering an environment where creativity thrives.",
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/team1.jpg'),
    alt: 'Team Image 1',
    dark: false,
    primary: false,
    darkText: true,
    lightBorder: true,
    button_link: ''
};

export const teamObjHistory = {
    id: 'history',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Club History',
    headline: 'Origins of UQ Fintech',
    description: "The University of Queensland Fintech Society was established in 2020, and our journey has been nothing short of exhilarating. In 2021, we successfully launched our first series of industry-sponsored events, marking a significant milestone in our mission to bridge the gap between students and the dynamic world of financial technology. Since then, we've been committed to providing valuable experiences and opportunities that empower our members for a promising future in fintech.",
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/team3.jpg'),
    alt: 'Team Image 3',
    dark: false,
    primary: false,
    darkText: true,
    lightBorder: true,
    button_link: ''
};

export const teamWillC = {
    id: 'our team',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Tier 3 Executive: President',
    headline: 'Will Cruickshank',
    description: "Since 2022, I've been a member of The UQ Fintech Society, and it stands as a highlight of my university experience. I've collaborated with remarkable students and met industry leaders. From relaxed poker nights for casual networking with industry professionals to engaging problem-solving sessions with a mix of brilliant minds, Fintech has something for everyone! Currently, I'm studying a Bachelor of Computer Science and Master of Data Science.",
    buttonLabel: '',
    imgStart: true,
    img: require('../../images/execs/Will.jpg'),
    alt: 'Will Cruickshank',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const teamLaraJ = {
    id: 'LaraJ',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Tier 3 Executive: Treasurer',
    headline: 'Lara Jabur',
    description: "As treasurer of the club since 2023, I've had the privilege of collaborating with outstanding peers and connecting with influential industry figures. From laid-back poker nights designed for casual networking to engaging problem-solving sessions that ignite creative thinking, Fintech offers a diverse range of experiences. Currently, I'm pursuing a Bachelor's degree in Economics and Commerce, and Fintech has significantly enriched my academic path.",
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/execs/Lara.JPG'),
    alt: 'Lara Jabur',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const teamLeoS = {
    id: 'LeoS',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Tier 3 Executive: Secretary',
    headline: 'Leo Saurini',
    description: "As an incoming Deloitte Access Economics intern with a strong passion for STEM applications, I've found Fintech to be the perfect complement to my academic pursuits in Commerce and Economics. Fintech's diverse range of activities, including casual networking events and stimulating problem-solving sessions, align seamlessly with my interests. With a specific focus on econometrics, my journey with Fintech has become an integral part of my educational and career development.",
    buttonLabel: '',
    imgStart: true,
    img: require('../../images/execs/Leo.JPG'),
    alt: 'Leo Saurini',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const teamMaxG = {
    id: 'MaxG',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Vice-President: Technology Portfolio',
    headline: 'Max Gadd',
    description: "My journey within Fintech has revolved around exploring technology's pivotal role in shaping finance. I take pride in having designed this very website, which now stands as our digital hub. In 2023, I've stepped into the leadership role of overseeing the technology portfolio, building on my prior experience as the VP of Culture. Fintech has not only honed my technical skills but has also granted me the opportunity to make a meaningful impact on our club's ongoing success.",
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/execs/Max.JPG'),
    alt: 'Max Gadd',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const teamAryanG = {
    id: 'AryanG',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Vice-President: Engagement Portfolio',
    headline: 'Aryan Goel',
    description: "Studying Computer Science and pursuing a Doctor of Medicine has deepened my appreciation for the intersection of healthcare and technology. My role allows me to bridge these fields, connecting our members with valuable opportunities in fintech and fostering a sense of community. It's an exciting journey, and I'm enthusiastic about the experiences that lie ahead.",
    buttonLabel: '',
    imgStart: true,
    img: require('../../images/execs/Aryan.JPG'),
    alt: 'Aryan Goel',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const teamAmyP = {
    id: 'AmyP',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Vice-President: Marketing Portfolio',
    headline: 'Amy Parker',
    description: "As a student pursuing a Bachelor of Mathematics and Geographical Science, my passion lies in the world of GIS spatial analytics. What drew me to fintech is the stimulating environment it offers for problem-solving. Fintech provides an exciting platform to apply diverse skills and innovate in this dynamic field, and I'm eager to explore the exciting opportunities it holds.",
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/execs/Amy.JPG'),
    alt: 'Amy Parker',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const teamCalebM = {
    id: 'CalebM',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Vice-President: Academia Portfolio ~ Science Faculty',
    headline: 'Caleb Mario',
    description: "I'm a third-year Mathematics and Economics student with a keen interest in problem-solving and analytical thinking. My engagement with a variety of UQ faculties and my role as a tutor reflect my commitment to continuous learning. I'm excited about my role within the society and to be part of this dynamic community, where I can contribute my skills and passion for fintech.",
    buttonLabel: '',
    imgStart: true,
    img: require('../../images/execs/Caleb.JPG'),
    alt: 'Caleb Mario',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const teamDomB = {
    id: 'DomB',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Vice-President: Academia Portfolio ~ BEL Faculty',
    headline: 'Dom Byrne',
    description: "I'm a student studying Advanced Finance and Economics, and I'm passionate about trading due to its fast-paced, problem-solving nature. I've had the privilege of attending SIG and IMC discovery programs, which have enriched my understanding of finance. In my free time, I enjoy golf, poker, and spending time with friends. My involvement with Fintech perfectly aligns with my interests and academic path, offering an exciting journey into the world of financial technology.",
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/execs/Dom.jfif'),
    alt: 'Dom Byrne',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const teamYousefA = {
    id: 'YousefA',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Vice-President: Sponsorship Portfolio',
    headline: 'Yousef Abdelhameed',
    description: "I’m a penultimate student at the University of Queensland studying Advanced Finance and Economics, with a specific interest in quant trading. This interest has earned me a summer internship at Citadel Securities, where I’m looking forward to gaining hands-on experience. As the VP of Sponsorship for the Fintech Society, I’m focused on fostering meaningful connections between students and the fintech industry.",
    buttonLabel: '',
    imgStart: true,
    img: require('../../images/execs/Yousef.jfif'),
    alt: 'Yousef Abdelhameed',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const teamJakeW = {
    id: 'JakeW',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Vice-President: Culture Portfolio',
    headline: 'Jake Williams',
    description: "In my current role as a Business Development Officer at Moneytech, I'm concurrently pursuing a Bachelor of Advanced Finance and Economics. This professional journey has afforded me valuable insights into the finance industry. Moreover, my academic pursuits, coupled with my professional interests, have rendered my experience with The UQ Fintech Society remarkably enriching.",
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/execs/Jake.jpg'),
    alt: 'Jake Williams',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const sponsorObjsponsors = {
    id: 'our sponsors',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Empowering the community',
    headline: 'Our Sponsors',
    description: "We extend our heartfelt thanks to our sponsors for their generous support. Their contributions are instrumental in creating valuable opportunities for our members. Engaging with sponsors is a pivotal part of the UQ Fintech Society experience which offers students the chance to network with industry leaders, discover career pathways, and access valuable opportunities. Take full advantage of the insights and connections our sponsors bring to the table, as they play a crucial role in shaping your future in the fintech industry.",
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/sig_poker_wide.jpg'),
    alt: 'Market Day Image',
    dark: false,
    primary: false,
    darkText: true,
    lightBorder: true,
    button_link: ''
};

export const sponsorObjbecome = {
    id: 'sponsor us',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Support our vision',
    headline: 'Sponsor Us!',
    description: "As the University of Queensland Fintech Society, we are driven by our commitment to bridge the gap between academic excellence and real-world fintech industries. Your sponsorship allows us to continue our mission, nurturing the future leaders of the financial technology industry. We invite you to partner with us and become an integral part of our journey. Together, we can shape the fintech leaders of tomorrow.",
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/sig_poker_presenting.jpg'),
    alt: 'Sponsor Us Image',
    dark: false,
    primary: false,
    darkText: true,
    lightBorder: true,
    button_link: ''
};

export const sponsorObjSIG = {
    id: 'SIG',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Our Sponsors',
    headline: 'Susquehanna International Group',
    description: "As one of the largest proprietary trading firms in the world, we trade our own capital at our own risk. We are experts in trading essentially all listed financial products and asset classes, with a focus on derivatives, and handle millions of transactions on exchanges around the world every day. While our presence in the market is broad, our trading desks are highly specialized, allowing for a deep understanding of the unique drivers of each product.",
    buttonLabel: '',
    imgStart: true,
    img: require('../../images/sponsors/sig.jfif'),
    alt: 'Sponsor Graphic',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const sponsorObjOptiver = {
    id: 'Optiver Trading',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Our Sponsors',
    headline: 'Optiver',
    description: "As a leading proprietary trading firm, Optiver works to make markets more efficient, transparent and stable for all. We have over 1,600 employees in offices around the world, united in their commitment to providing liquidity, competitive pricing and reliable risk management.",
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/sponsors/optiver.jpg'),
    alt: 'Sponsor Graphic',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const sponsorObjVivcourt = {
    id: 'Vicourt Trading',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Our Sponsors',
    headline: 'Vivcourt Trading',
    description: "At VivCourt, we’re part of an industry that’s heavily focused on making money. We’re an active part of that and we’re proud of our trading success. Additionally, through our employee social bonus system and various social impact initiatives, we connect our company and our employees to something much bigger than ourselves. We use part of the money that we make, and the skills and capabilities that we have, for a purpose that we can be proud of.",
    buttonLabel: '',
    imgStart: true,
    img: require('../../images/sponsors/Vivcourt.jpg'),
    alt: 'Sponsor Graphic',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const sponsorObjIMC = {
    id: 'IMC Trading',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Our Sponsors',
    headline: 'IMC Trading',
    description: "Cutting-edge technology is at the heart of all we do. We put it to work in the markets that define our world. Across offices in Amsterdam, Chicago, Sydney and Mumbai, technologists and traders team up to explore, design and develop the software, hardware, advanced networks and algorithms that drive our trading strategies.",
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/sponsors/imc.png'),
    alt: 'Sponsor Graphic',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const sponsorObjMantelGroup = {
    id: 'Mantel Group',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Our Sponsors',
    headline: 'Mantel Group',
    description: "Our brands enable our team members to unite and drive deep specialisation, experience and immersion in key technologies and partners. Amplified by our philosophy of empowering teams to execute and strive for federated leadership, we assemble the best teams to create tailored solutions and value for our clients, anchored by expertise in the technology ecosystem.",
    buttonLabel: '',
    imgStart: true,
    img: require('../../images/sponsors/mantel_group.jpg'),
    alt: 'Sponsor Graphic',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const sponsorObjAkuna = {
    id: 'Akuna Capital',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Our Sponsors',
    headline: 'Akuna Capital',
    description: "Akuna Capital is an innovative trading firm with a strong focus on collaboration, cutting-edge technology, data-driven solutions and automation. We specialize in providing liquidity as an options market maker – meaning we are committed to providing competitive quotes that we are willing to both buy and sell. To do this successfully we design and implement our own low-latency technologies, trading strategies and mathematical models.",
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/sponsors/akuna.webp'),
    alt: 'Sponsor Graphic',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const sponsorObjJaneStreet = {
    id: 'Jane Street Capital',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Our Sponsors',
    headline: 'Jane Street Capital',
    description: "We were founded by a small group of traders and technologists in a tiny New York office. Today, we have more than 2,000 employees across five global offices. We trade a broad range of asset classes on more than 200 venues in 45 countries. We innovate in tech, from functional programming to programmable hardware. And while our proprietary trading business thrives, we put our expertise and experience to work for our clients.",
    buttonLabel: '',
    imgStart: true,
    img: require('../../images/sponsors/jane_street.jfif'),
    alt: 'Sponsor Graphic',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const sponsorObjRedBull = {
    id: 'Red Bull',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Our Sponsors',
    headline: 'Red Bull',
    description: "Red Bull's commitment to energy and enthusiasm is directly aligned with the fast-paced nature of fintech. We actively support the UQ Fintech Society and their social events, and love to see members of the society enjoying our wide variety of energy drinks.",
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/sponsors/red_bull.jpg'),
    alt: 'Sponsor Graphic',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const eventsObjOverview = {
    id: 'events overview',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Events Calendar',
    headline: 'Fintech Events',
    description: "At UQ Fintech, our events are a fusion of learning, networking, and giving back. Explore a variety of engaging activities, from social gatherings to collaborative events with other clubs and charitable initiatives. Our signature events like the Problem Solving and Industry Night, along with Industry Poker Nights, provide unique opportunities to network with industry leaders, hone problem-solving skills, and contribute to meaningful causes. For detailed event updates, please be sure to check our Facebook page and Discord server.",
    buttonLabel: 'Facebook Events Page',
    imgStart: false,
    img: require('../../images/events/group_photo.jpg'),
    alt: 'Events Generic Image',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: 'https://www.facebook.com/UQFintech/events'
};

export const eventsObjProblemSolving = {
    id: 'problem solving events',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Our Events',
    headline: 'Problem Solving Events',
    description: "Discover your fintech potential through our engaging events. From relaxed poker nights and insightful trading workshops to mind-bending brainteaser challenges, we offer unique opportunities to sharpen your problem-solving abilities.",
    buttonLabel: '',
    imgStart: true,
    img: require('../../images/events/poker_generic.jpg'),
    alt: 'Events Graphic',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const eventsObjSocial = {
    id: 'social events',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Our Events',
    headline: 'Social Events',
    description: "Experience the vibrant side of fintech with our social events. Our Industry Barbecues offer the opportunity to network with professionals in a relaxed outdoor setting, while Social Poker nights provide a fun, casual atmosphere to meet like-minded peers. And as each semester winds down, our end-of-semester drinks bring members together to celebrate achievements and build lasting connections in a more informal setting.",
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/events/barbecue.jpg'),
    alt: 'Events Graphic',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const eventsObjCollaborations = {
    id: 'collaboration events',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Our Events',
    headline: 'Collaboration Events',
    description: "We believe in fostering connections beyond our boundaries. Through collaboration with various UQ clubs and societies, we've co-created unique events like the inaugural Science Ball, energetic pub crawls, and exciting sporting competitions. These events unite students from diverse disciplines, enhancing the fintech experience by bringing together a spectrum of knowledge and expertise.",
    buttonLabel: '',
    imgStart: true,
    img: require('../../images/events/science_ball.jpg'),
    alt: 'Events Graphic',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const eventsObjCharity = {
    id: 'charity events',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Our Events',
    headline: 'Charity Events',
    description: "We are dedicated to making a positive impact in the community. The UQ Fintech Society actively participates in, and encourages its members to support, various charity initiatives. These endeavors exemplify our commitment to giving back and making a difference beyond the fintech landscape.",
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/events/fun_run.jpg'),
    alt: 'Events Graphic',
    dark: false,
    primary: true,
    darkText: true,
    lightBorder: false,
    button_link: ''
};

export const eventsObjAlgothon = {
    id: 'algothon',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Algorithmic Trading',
    headline: 'UQ Algo-Jam 2024',
    description: "This digital trading challenge is a thrilling 48-hour competition that will put participants coding, collaboration, and problem-solving skills to the ultimate test. The goal of the Algo-Jam will be for students to back-test and implement algorithmic approaches to generate profit in simulated markets.",
    buttonLabel: 'Event Info',
    imgStart: false,
    img: require('../../images/algothon_banner.png'),
    alt: 'Algothon Banner',
    dark: false,
    primary: false,
    darkText: true,
    lightBorder: true,
    button_link: '/algothon'
};

export const mainObjAlgothon = {
    id: 'algothonInfo',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'UQ Algo-Jam 2024',
    headline: 'Competition Info',
    description: "This digital trading challenge is a thrilling 48-hour competition that will put participants coding, collaboration, and problem-solving skills to the ultimate test. The goal of the Algo-Jam will be for students to back-test and implement algorithmic approaches to generate profit in simulated markets.",
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/returns_plot.png'),
    alt: 'Algothon Banner',
    dark: true,
    primary: false,
    darkText: true,
    lightBorder: false,
    button_link: ''
};