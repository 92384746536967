import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarMenu, SidebarWrapper, SidebarLink, SidebarRoute, SideBtnWrap} from './SidebarElements'

const TeamSidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to="events overview" onClick={toggle} smooth="true"  exact="true" offset={-80} >
                    Calendar
                </SidebarLink>
                <SidebarLink to="problem solving events" onClick={toggle} smooth="true"  exact="true" offset={-80} >
                    Problem Solving
                </SidebarLink>
                <SidebarLink to="social events" onClick={toggle} smooth="true"  exact="true" offset={-80} >
                    Social
                </SidebarLink>
                <SidebarLink to="collaboration events" onClick={toggle} smooth="true"  exact="true" offset={-80} >
                    Collaborations
                </SidebarLink>
                <SidebarLink to="charity events" onClick={toggle} smooth="true"  exact="true" offset={-80} >
                    Charity
                </SidebarLink>
                <SideBtnWrap>
                    <SidebarRoute to="/signin" onClick={toggle} smooth="true"  exact="true" offset={-80} >
                        Sign in
                    </SidebarRoute>
                </SideBtnWrap>
            </SidebarMenu>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default TeamSidebar