import styled from 'styled-components';
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md';

export const HeroContainer = styled.div`
    background: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 600px;
    position: relative;
    z-index: 1;
    
    :before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0.9) 0%, transparent 60%),
                    linear-gradient(180deg, rgba(0,0,0,0.9) 0%, transparent 40%),
                    linear-gradient(0deg, rgba(0,0,0,0.9) 0%, transparent 40%),
                    linear-gradient(0deg, rgba(0,0,0,0.9) 0%, transparent 40%);
        z-index: 2;
    }
`;

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0; 
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const VideoBg = styled.video`
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #000000;
`;

export const Content = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: abosolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2em;
`

export const HeroContent = styled.div`
    backdrop-filter: blur(8px);
    border: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
    border-image-source: linear-gradient(to right, #add8e6, #8d5bee);
    padding: 16px 48px;
    @media screen and (max-width: 768px) {
        padding: 12px 36px;
    }

    @media screen and (max-width: 480px) {
        padding: 8px 24px;
    }
`;

export const HeroH1 = styled.h1`
    color: #fff;
    font-size: 72px;
    text-align: center;

    @media screen and (max-width: 768px) {
        font-size: 40px;
    }

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const HeroP = styled.p`
    margin-top: 24px;
    color: #fff;
    font-size: 24px;
    font-align: center;
    text-align: center;
    max-width: 600px;
    

    @media screen and (max-width: 768px) {
        font-size: 24px;
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`;

export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`;

export const A = styled.a`
    text-decoration: none;
`