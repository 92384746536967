import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import SignUp from '../components/SignUp';

const SignUpPage = () => {

  return (
    <>
        <ScrollToTop />
        <SignUp />
    </>
  )
}

export default SignUpPage