import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarMenu, SidebarWrapper, SideBtnWrap} from './SidebarElements'
import { Button } from '../ButtonElement';
import { UserAuth } from '../../context/AuthContext';

const SidebarDashboard = ({isOpen, toggle}) => {
    const {logOut} = UserAuth();

    const handleSignOut = async () => {
        toggle();
        try {
            await logOut()
        } catch (error) {
            console.log(error)
        }
    }
    return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SideBtnWrap>
                    <Button onClick={handleSignOut}>
                        Sign Out
                    </Button>
                </SideBtnWrap>
            </SidebarMenu>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default SidebarDashboard