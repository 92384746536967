import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import NavbarDashboard from '../components/NavbarDashboard';
import { UserAuth } from '../context/AuthContext';
import { useState } from 'react';
import SidebarDashboard from '../components/SidebarDashboard';
import {MainContainer, BorderContainer } from '../components/UserDashboard/DashboardComponents';
import Footer from '../components/Footer';


const TradingComp = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    };
    
    return (
        <>
        <ScrollToTop />
        <SidebarDashboard isOpen={isOpen} toggle={toggle}/>

        <NavbarDashboard toggle={toggle}/>
        <MainContainer style={{backgroundColor: "black", textAlign: "center"}}>
            <BorderContainer>
                <h2>
                    Sample Text
                </h2>
            </BorderContainer>
        </MainContainer>
        <Footer />
        </>
    )
}

export default TradingComp