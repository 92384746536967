import styled from "styled-components";

export const ContentContainer = styled.div`
    width: 80%;
    padding-top: 1em;
`

export const Socials = styled.ul `
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto; 
    padding: 5px;
    list-style-type: none;
`

export const SocialLinks = styled.svg `
padding-bottom: 10px;
    &:hover {
        
        border-bottom: 4px solid white;
        transition:.2s ease;
    }
`;