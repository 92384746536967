import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import Spinner from '../components/Spinner';

const SpinnerPage = () => {

  return (
    <>
        <ScrollToTop />
        <Spinner />
    </>
  )
}

export default SpinnerPage