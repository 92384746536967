import React, {useState, useEffect} from 'react';
import {FaBars} from 'react-icons/fa';
import Logo from '../../../images/logo.png';
import {Nav, NavbarContainer, NavLogo, NavMenu, NavItem, NavLinks, MobileIcon, NavBtn, NavBtnLink} 
    from './NavbarElements';
import {animateScroll as scroll} from 'react-scroll';

const SponsorNavbar = ({toggle}) => {

    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = ()=> {
        if(window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <NavLogo to='/' onClick={toggleHome}>
                        <img src={Logo} alt="Logo" style={{ width: '50px', padding: '5px',}}></img>
                        UQ Fintech 
                    </NavLogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to='our sponsors' smooth="true" exact="true" offset={-80} >
                                Community
                            </NavLinks>
                        </NavItem>
                    </NavMenu>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to='SIG' smooth="true" exact="true" offset={-80} >
                                Our Sponsors
                            </NavLinks>
                        </NavItem>
                    </NavMenu>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to='sponsor us' smooth="true" exact="true" offset={-80} >
                                Sponsor Us
                            </NavLinks>
                        </NavItem>
                    </NavMenu>
                    <NavBtn>
                        <NavBtnLink to="/signin" smooth="true" exact="true" offset={-80} >
                            Sign In
                        </NavBtnLink>
                    </NavBtn>
                </NavbarContainer>
            </Nav>
        </>
    );
};

export default SponsorNavbar