import React from 'react';
import background from '../../images/userGradient.jpg';
import {HeroContainer, HeroContent, HeroH1} from './HeroElements';

const HeroUserDash = () => {

    return (
        <HeroContainer style={{ backgroundImage: `url(${background})` }}>
            <HeroContent>
                <HeroH1>
                    Welcome to UQ Fintech
                </HeroH1>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroUserDash