import React from 'react';
import { Button } from '../ButtonElement';
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img, A} from './InfoElements';

const SmallInfoSection = ({lightBg, id, imgStart, topLine, lightText, lightTextDesc, description, buttonLabel, img, alt, headline, lightBorder, primary, dark, button_link}) => {
  return (
    <>
        <InfoContainer lightBg={lightBg} id={id}>
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1>
                        <center>
                        <TextWrapper>
                            <TopLine>
                                {topLine}
                            </TopLine>
                            <Heading lightText={lightText}>
                                {headline}
                            </Heading>
                            <Subtitle lightTextDesc={lightTextDesc}>
                                {description}
                            </Subtitle>
                            {buttonLabel != "" &&
                            <BtnWrap>
                                <A href={button_link}>
                                <Button smooth={true} duration={500} spy={true} exact="true" offset={-80} primary={primary ? 1 : 0} dark={dark ? 1 : 0}> 
                                    {buttonLabel} 
                                </Button>
                                </A>
                            </BtnWrap>
                            }
                        </TextWrapper>
                        </center>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                            <center>
                            <Img src={img} alt={alt} lightBorder={lightBorder}/>
                            </center>
                        </ImgWrap>
                    </Column2>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
    </>
  )
}

export default SmallInfoSection