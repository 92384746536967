import styled from 'styled-components';

export const Button = styled.button`
    border-radius: 50px;
    background: ${({primary}) => (primary ? '#000000' : '#8d5bee')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#8d5bee' : '#FFFFFF')};
    font-size: ${({fontBig}) => (fontBig ? '24px' : '18px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;


    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#8d5bee' : '#FFFFFF' )};
        color: ${({primary}) => (primary ? '#FFFFFF' : '#8d5bee')};
    }
`;