import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import NavbarDashboard from '../components/NavbarDashboard';
import { UserAuth } from '../context/AuthContext';
import { useState } from 'react';
import SidebarDashboard from '../components/SidebarDashboard';
import {MainContainer, BorderContainer } from '../components/UserDashboard/DashboardComponents';
import HeroUserDash from '../components/HeroUserDash';
import Footer from '../components/Footer';
import { useEffect } from 'react';
import { database } from '../firebaseServices';

const User = () => {

    const {user, setUserData, updateUserData, getUserData} = UserAuth();

    const [isOpen, setIsOpen] = useState(false);

    const [membershipFontColour, setColour] = useState("Orange");

    const [data, refreshUserData] = useState({  
        displayName: null, 
        desc: null, 
        role: "user", 
        subscriptions: null, 
        membership: "unavailable", 
        registrations: null  
    }) ;

    const toggle = () => {
        setIsOpen(!isOpen)
    };
    
    useEffect(() => {
        if (user != null && user.uid != null) {
          const uid = user.uid;
          try {
            getUserData(database, uid).then(newData => {
                var userData = newData
                console.log(uid)
                console.log(userData);
                if (userData) {
                    console.log("UserData Found")
                } else {
                    console.log("UserData Not Found")
                    userData = { 
                    displayName: "", 
                    desc: "", 
                    role: "user", 
                    subscriptions: "", 
                    membership: "inactive", 
                    registrations: ""  
                }
                setUserData(database, uid, userData).then(() => {
                    console.log("Set Userdata")
                    window.location.reload();
                })
            }
            refreshUserData(userData);
            });
          } catch (error) {
            console.log("Error Validating: ", error);
          }
        }
      }, [user, getUserData, setUserData]);

      useEffect(() => {
        if (data) {
            if (data['membership'] === "active") {
                setColour("Green");
            } else if (data['membership'] === "inactive") {
                setColour("Orange");
            } else {
                setColour("Red");
            }
        }
      }, [data]);

    return (
        <>
        <ScrollToTop />
        <SidebarDashboard isOpen={isOpen} toggle={toggle}/>

        <NavbarDashboard toggle={toggle}/>
            <HeroUserDash style={{
                marginTop: "-80px"
            }} />
        <MainContainer style={{backgroundColor: "black", textAlign: "center"}}>
            <BorderContainer>
                <h2>
                    Your Membership Status
                </h2>
                <h2 style={{
                    color: membershipFontColour
                }}>
                {data["membership"]}
                </h2>
            </BorderContainer>
        </MainContainer>
        <Footer />
        </>
    )
}

export default User