import { useContext, useState, createContext, useEffect} from "react";
import { GoogleAuthProvider, 
    signInWithPopup, 
    signOut,
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    onAuthStateChanged } from "firebase/auth";
import { firebaseAuth } from "../firebaseServices";
import {doc, setDoc, getDoc, updateDoc} from "firebase/firestore";

const AuthContext = createContext();
export const AuthContextProvider = ({children}) => {
    
    const [user, setUser] = useState({});

    const googleSignIn = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(firebaseAuth, provider)
    }

    function signIn(auth, email, password) {
        return signInWithEmailAndPassword(auth, email, password);
    }

    function signUp(auth, email, password) {
        return createUserWithEmailAndPassword(auth, email, password);
    }

    function resetPwd(auth, email) {
        return sendPasswordResetEmail(auth, email)
    }

    const logOut = () => {
        signOut(firebaseAuth)
    }

    //gets data of a given user form the users table 
    async function getUserData(database, uid) {
        try {
            const docRef = doc(database, "users", uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                console.log("Read Data Successfully.");
                return docSnap.data();
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
                return null;
            }
        } catch (e) {
            console.log("Error getting doc: ", e);
            signOut(firebaseAuth);
            return null;
        }
    }

    //creates a new user document for given user and takes in all data as a param
    async function setUserData(database, uid, allData) {
        try {
            await setDoc(doc(database, "users", uid), allData);  
        }
        catch (e) {
            console.log("Error setting doc: ", e);
            signOut(firebaseAuth);
        }
    }

    //updates data of a given user in the users table, the data assigned to a particular feild
    async function updateUserData(database, uid, allData) {
        try {
            const userRef = doc(database, "users", uid);
            await updateDoc(userRef, allData);
        } catch (e) {
            console.log("Error updating doc: ", e);
            signOut(firebaseAuth);
        }
    }

    useEffect(()=> {
        const unsubscribe = onAuthStateChanged(firebaseAuth, (currentUser) => {
            setUser(currentUser);
        });
        return () => {
            unsubscribe();
        };
      }, []);

    return (
        <AuthContext.Provider value={{googleSignIn, logOut, user, signUp, signIn, resetPwd, getUserData, setUserData, updateUserData}}>
            {children}
        </AuthContext.Provider>
    )
}

export const UserAuth =() => {
    return useContext(AuthContext)
}