import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarMenu, SidebarWrapper, SidebarLink, SidebarRoute, SideBtnWrap} from './SidebarElements'

const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to="about" onClick={toggle} smooth="true"  exact="true" offset={-80} >
                    The Society
                </SidebarLink>
                <SidebarLink to="events" onClick={toggle} smooth="true"  exact="true" offset={-80} >
                    Events
                </SidebarLink>
                <SidebarLink to="sponsors" onClick={toggle} smooth="true"  exact="true" offset={-80} >
                    Our Sponsors
                </SidebarLink>
                <SideBtnWrap>
                    <SidebarRoute to="/signin" onClick={toggle} smooth="true"  exact="true" offset={-80} >
                        Sign in
                    </SidebarRoute>
                </SideBtnWrap>
            </SidebarMenu>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar