import React, {useState} from 'react';
import Video from '../../videos/hero1080p.mp4';
import {HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, ArrowForward, ArrowRight, HeroBtnWrapper, A, Content} from './HeroElements';
import { Button } from '../ButtonElement';
import Socials3D from '../Socials3D'

const HeroSection = () => {

    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }

    return (
        <HeroContainer>
            <HeroBg>
                <VideoBg autoPlay loop muted playsInline src={Video} type='video/mp4' />
            </HeroBg>
            <Content>
            <HeroContent>
                <HeroH1>
                    The UQ Fintech Society
                </HeroH1>
                <HeroBtnWrapper>
                <A href="https://uqfintech.getqpay.com/" target="_blank" rel="noreferrer">
                    <Button onMouseEnter={onHover} onMouseLeave={onHover}>
                    Join Us {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </A>
                </HeroBtnWrapper>
            </HeroContent>
            <Socials3D />
            </Content>
        </HeroContainer>
    )
}

export default HeroSection