import styled from "styled-components";

export const BackgroundDiv = styled.div`
    background-color: black;
    width: 100%;
    height: 100vh;
    `;

export const MainContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
  `;

  export const BorderContainer = styled.div`
  padding: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
  background: black;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  @media only screen and (min-width: 0px) {
    width: 100vw;
    height: auto;
    hr {
      margin-bottom: 0.3rem;
    }
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 360px) {
    width: 90vw;
    height: auto;
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 411px) {
    width: 80vw;
    height: auto;
  }
  @media only screen and (min-width: 768px) {
    width: 80vw;
    height: auto;
  }
  @media only screen and (min-width: 1024px) {
    width: 70vw;
    height: auto;
  }
  @media only screen and (min-width: 1280px) {
    width: 30vw;
    height: auto;
  }

  /*code for border animation*/ 
`;
  