import React, {useState, useEffect} from 'react';
import {Button} from "../ButtonElement";
import Icon from "./Icon";
import { StyledInput} from "./Input";
import Logo from '../../images/logo.png';
import { FaGoogle} from "react-icons/fa";
import { WelcomeText, InputContainer, ButtonContainer, LoginWith, IconsContainer, LogoHeader,
    ForgotPassword, MainContainer, BackgroundDiv, ArrowForward, ArrowRight, BorderContainer} from "./SignUpComponents";
import background from "../../images/loginGradient.jpg"
import { UserAuth } from '../../context/AuthContext';
import { Link as LinkR} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import Alert from "react-bootstrap/Alert";
import { firebaseAuth } from "../..//firebaseServices";

function SignUp() {

    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const [hover, setHover] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const {googleSignIn, user, signUp} = UserAuth();

    async function handleSubmit(e) {
      e.preventDefault();
      if (passwordRef.current.value !== passwordConfirmRef.current.value) {
        return setError('Passwords do not match')
      }
      try {
        setError('')
        setLoading(true)
        await signUp(firebaseAuth, emailRef.current.value, passwordRef.current.value);
      } catch (e) {
        setError('Failed to create an account.')
        console.log(e.message)
      }
      setLoading(false)      
    }

    const onHover = () => {
        setHover(!hover);
    }
    
    const handleGoogleSignIn = async () => {
      try {
        await googleSignIn();
      } catch (error) {
        console.log(error);
      }
    }

    const navigate = useNavigate();

    useEffect(() => {
      if(user != null && user.uid != null) {
        navigate('/user')
      }
    }, [user, navigate]);

  const GoogleBackground =
    "black";

    async function gotoLogin() {
      navigate('/signin')
    }

  return (
    <BackgroundDiv style={{ backgroundImage: `url(${background})` }}>
    <MainContainer>
      <BorderContainer>
      <LinkR to="/" style={{textDecoration: "none"}}>
      <LogoHeader>
        <img src={Logo} alt="Logo" style={{ width: '1.75rem', paddingRight: '5px',}}></img>
        <p style={{marginRight: '5px'}}> UQ Fintech</p>
      </LogoHeader>
      </LinkR>
      <WelcomeText>Create Account</WelcomeText>
  
      <form style={{width: "80%"}} onSubmit={handleSubmit}>
      <InputContainer>
        <input type="email" placeholder="Email" ref={emailRef} style={StyledInput} required/>
        <input type="password" placeholder="Password" ref={passwordRef} style={StyledInput} required/>
        <input type="password" placeholder="Confirm Password" ref={passwordConfirmRef} style={StyledInput} required/>
        {error && <Alert variant="danger">{error}</Alert>}
      </InputContainer>
      <ButtonContainer>
        <Button onMouseEnter={onHover} onMouseLeave={onHover} type="submit" disabled={loading}>
            Create {hover ? <ArrowForward /> : <ArrowRight />}
        </Button>
      </ButtonContainer>
      </form>
      <LoginWith>OR REGISTER WITH</LoginWith>
      <IconsContainer>
        <Icon color={GoogleBackground}>
          <FaGoogle onClick={handleGoogleSignIn} />
        </Icon>
      </IconsContainer>
      </BorderContainer>
        <ForgotPassword onClick={gotoLogin}>
          Have an account? Login Here!
        </ForgotPassword>
      </MainContainer>
    </BackgroundDiv>
  );
}

export default SignUp;