import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import Navbar from '../components/Navbar';
import { useState } from 'react';
import SidebarDashboard from '../components/SidebarDashboard';
import {MainContainer, BorderContainer } from '../components/UserDashboard/DashboardComponents';
import Footer from '../components/Footer';
import SmallInfoSection from '../components/SmallInfoSection';
import { mainObjAlgothon } from '../components/InfoSection/Data';
import bannerImage from "../images/algothon_banner.png"

const Algothon = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    };
    
    // JavaScript function to download the PDF
    const handleClick = () => {
        const link = document.createElement('a');
        link.href = '../algothon/AlgoJamInfo2024.pdf'; // Path to the PDF file
        link.download = 'AlgoJamInfo.pdf'; // Suggested file name for download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    // Inline styles for the button
    const buttonStyle = {
        border: '5px solid #8d5bee', // Purple border
        backgroundColor: 'white',   // Background color
        height: '5em',             // Height of the button
        padding: "2em",
        color: "black",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',          // Pointer cursor on hover
        outline: 'none',            // Remove outline on focus
        boxShadow: "0 0 10px rgba(141, 91, 238, 0.8), 0 0 20px rgba(141, 91, 238, 0.6)", 
        transition: "box-shadow 0.3s"
    };

    return (
        <>
        <ScrollToTop />
        <SidebarDashboard isOpen={isOpen} toggle={toggle}/>
        <Navbar toggle={toggle}/>
        <div style={{background: "black", height: "80px", width: "100%"}} />
        <MainContainer style={{backgroundColor: "black", textAlign: "center"}}>
            <img style={{maxWidth:"100%", maxHeight:"80%"}} src={bannerImage}></img>
            <br></br>
            <h3 style={{padding: "1em"}}>The University of Queensland Fintech Society is excited to present our first ever Algo-Jam!</h3>
            <br></br>

        </MainContainer>
        <SmallInfoSection {...mainObjAlgothon}/>
        <MainContainer style={{backgroundColor: "white", textAlign: "center", color:"black"}}>
            <div style={{backgroundColor: "white", textAlign: "left", color:"black", textTransform: "none", letterSpacing: "normal", textAlign: "left", paddingLeft:"10%", paddingRight:"10%"}}>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center", gap: "3em"}}>
                    <a href="https://www.trybooking.com/events/landing/1275430">
                        <button style={{
                            ...buttonStyle
                        }}>
                            <h2>REGISTER HERE</h2>
                        </button>
                    </a>
                    <button style={{
                        ...buttonStyle
                    }} onClick={handleClick}>
                        <p>Download Algo-Jam Information Sheet</p>
                    </button>
                    <a href="https://discord.gg/invite/QxJaQr83VB">
                        <button style={{
                            ...buttonStyle
                        }}>
                            <p>Discord Server</p>
                        </button>
                    </a>
                </div>
                <br></br><br></br>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center", gap: "3em"}}>
                <h3 style={{padding: "1em"}}>Source Code, Data and Documentation! --></h3>
                    <a href="https://github.com/UqFintech/ALGOJAM24_RELEASE">
                        <button style={{
                            ...buttonStyle
                        }}>
                            <h2>GITHUB</h2>
                        </button>
                    </a>
                </div>

                <br></br><br></br><br></br>
                <h1>Event Schedule</h1>
                <br></br>
                <div style={{borderWidth:"3px", borderColor:"black"}}>
                    <h2>Friday (Hybrid Launch Event)</h2>
                    <p><strong>6:00 PM Event Launch</strong> - The competition will kick-off in person at the UQ GHD Auditorium (49-200), and presented in a hybrid format.<br></br>Participants will be introduced to the criteria, guidelines, and schedule. The following will also be completed during this time:</p>
                    <br></br>
                    <ul>
                        <li><strong>Sponsor Introductions</strong> - During this time, the Judges will be introduced, and IMC will have time to introduce themselves and their role in the trading industry.</li>
                        <li><strong>Team Making</strong> - During this time, participants will also get the chance to talk to one another, decide upon their team and submit their form. A team size of 4 is the maximum.</li>
                        <li><strong>Prizes</strong> - Prizes will also be introduced during the opening.</li>
                        <li><strong>Information Release</strong> – Algorithmic data to be released.</li>
                        <li><strong>Rules and Guidelines</strong> - Participants will be briefed on the rules, submission requirements, and evaluation method.</li>
                        <li><strong>Q&A Session</strong> - An opportunity for participants to seek clarifications on the competition guidelines.</li>
                        <li><strong>Dinner</strong> – Pizza will be provided for dinner this evening.</li>
                    </ul>
                </div>

                <br></br><br></br>
                <div style={{borderWidth:"3px", borderColor:"black"}}>
                    <h2>Friday to Sunday (Competition Duration)</h2>
                    <p>Participants have a total of 48 hours to work on their solutions, collaborate with team members, and develop innovative trading approaches to the provided market data.</p>
                    <p>The University of Queensland Fintech Society will provide a designated room (Hawken Engineering, Room 201) where participants can gather and collaborate over the competition weekend.</p>
                </div>

                <br></br><br></br>
                <div style={{borderWidth:"3px", borderColor:"black"}}>
                    <h2>Saturday and Sunday (BBQ Lunch and Collaboration Days)</h2>
                    <p>The designated UQ Campus Location will be open for participants to brainstorm, ideate, and collaborate with team members and other participants. Feel free to ask for help from the UQ Fintech executive committee, as we will be there to give helpful advice throughout.</p>
                    <p>UQ Fintech will provide catering throughout the event including lunches, soft drinks, and snacks, creating a conducive environment for idea sharing and discussions.</p>
                    <p>Location: Hawken Engineering Room s201 (50-s201). 10am - 2pm.</p>
                </div>

                <br></br><br></br>
                <div style={{borderWidth:"3px", borderColor:"black"}}>
                <   h2>Submissions</h2>
                    <p>Submissions will be open until <strong>6pm Sunday evening</strong>. Please let a member of the executive team know if there are issues with submission. Late submissions will be penalised by an accumulating -5% deduction from their final score, for every 10 minutes after the submission deadline. Late submissions received after <strong>6pm</strong> will not be considered.</p>
                </div>

                <br></br><br></br>
                <div style={{borderWidth:"3px", borderColor:"black"}}>
                    <h2>Monday (Presentation Evening)</h2>
                    <p>All participants will gather for an evening of presentations and networking with sponsors. The evening will be catered, offering participants and sponsors an opportunity to interact in a relaxed setting.</p>
                    <br></br>
                    <p>Winners will be announced and awarded exciting prizes for their outstanding performances. These teams will also be given the offer to showcase their solutions and innovative ideas to the audience and the judging panel.</p>
                    <p>Afterwards participants will have the chance to engage with our esteemed sponsor IMC Trading, to foster valuable connections and potential career opportunities.</p>
                </div>

                <br></br><br></br>
                <div style={{borderWidth:"3px", borderColor:"black"}}>
                    <h2>Points of Contact and Support</h2>
                    <p>If you have any questions or queries, please contact the executive team via the UQ Fintech Facebook page or Discord.</p>
                    <p>The UQ Fintech team will be present to provide support throughout the competition, as we aim for students and competitors from a variety of backgrounds to be able to enhance their algorithmic problem-solving capabilities.</p>
                    <br></br>
                    <p>For technical questions or corrections, please contact <strong>Max Gadd, Executive of Technology</strong>, at <a href="mailto:technology@uqfintech.org">technology@uqfintech.org</a>, via Discord @maxgadd, or via other feasible means.</p>
                    <br></br><br></br>
                    <p>For campus emergencies during the competition please contact <strong>UQ Security: (07) 3365 3333</strong>.</p>
                    <p>For medical emergencies call <strong>000</strong>.</p>
                </div>
                <br></br><br></br>

            </div>
        </MainContainer>
        <Footer />
        </>
    )
}

export default Algothon