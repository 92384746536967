import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import SignIn from '../components/SignIn';

const SignInPage = () => {

  return (
    <>
        <ScrollToTop />
        <SignIn />
    </>
  )
}

export default SignInPage