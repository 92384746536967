import styled from 'styled-components';
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md';

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
`;

export const WelcomeText = styled.h2`
  margin: 1rem 0 1rem 0;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 20%;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  margin: 1rem 0 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const LoginWith = styled.h5`s
  height: 1rem;
  padding: 0;
  margin: 0;
  letter-spacing: 0.2rem;
  margin: 1rem 0 0 0;
`;

export const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ForgotPassword = styled.h5`
cursor: pointer;
margin: 1rem 1rem;
letter-spacing: 0.3rem;
`;

export const BackgroundDiv = styled.div`
    background-color: black;
    background-position: center;
    display: flex;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    @media only screen and (max-height: 600px) {
      width: 100vw;
      height: 120vh;
    }
    @media only screen and (max-height: 400px) {
      width: 100vw;
      height: 230vh;
    }
    @media only screen and (max-height: 260px) {
      width: 100vw;
      height: 300vh;
    }
    @media only screen and (max-height: 150px) {
      width: 100vw;
      height: 370vh;
    }
    @media only screen and (max-width: 350px) {
      width: 95vw;
      height: 120vh;
    }
    @media only screen and (max-width: 359px) {
      width: 95vw;
      height: 120vh;
    }
`;

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`;

export const BorderContainer = styled.div`
  padding: 2rem;
  display: flex;
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to right, #add8e6, #8d5bee);
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
  width: 30vw;
  background: black;
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 10px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  @media only screen and (min-width: 0px) {
    width: 100vw;
    height: auto;
    hr {
      margin-bottom: 0.3rem;
    }
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 360px) {
    width: 90vw;
    height: auto;
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 411px) {
    width: 80vw;
    height: auto;
  }
  @media only screen and (min-width: 768px) {
    width: 80vw;
    height: auto;
  }
  @media only screen and (min-width: 1024px) {
    width: 70vw;
    height: auto;
  }
  @media only screen and (min-width: 1280px) {
    width: 30vw;
    height: auto;
  }
`;

export const LogoHeader = styled.div`
    color: #fff;
    justify-self: flex-start;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
    letter-spacing: 0rem;
    margin-top: 0rem;
`;

export const ErrorText = styled.p`
  color: white;
  text-transform: uppercase;
`